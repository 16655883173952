<script>
import CreativesScreenshots from "@/views/CompetitorsFinderPage/Competitors/Creatives/Screenshots.vue";

export default {
  name: "CreativesAll",
  components: {CreativesScreenshots},
  props: {
    creativesState: {
      type: Object,
      default: null,
    },
  },
}
</script>

<template>
<creatives-screenshots :creatives-state="creativesState" :show-logo="true"></creatives-screenshots>
</template>

<style scoped lang="scss">

</style>