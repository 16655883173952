<template>
  <div class="competitors-finder-page-content">
    <template v-if="!resultsLoading">

      <div class="competitors-wrap">

        <user-limits-exceeded v-if="overheadType && overheadType !== 'appsOverhead'">
          You have exceeded the limits of keywords and competitors for your account. Please, delete some keywords and
          competitors to fit within the current account limits.
        </user-limits-exceeded>

        <div class="progress-container-wrap" :key="currentApp.id">

          <progress-bars-heading :app-id="currentApp?.id"
                                 :current-country="currentCountry"
                                 :competitor-count-changed="competitorsCount"/>
        </div>

        <div class="tabs-block">
          <div class="tab-selector flex-wrap">
            <router-link :to="{name: 'MyCompetitors'}" :class="{'not-active' : activeTab !== 'MyCompetitors'}" exact>
              <span>My Competitors</span>
            </router-link>
            <router-link :to="{name: 'Creatives'}" :class="{'not-active' : activeTab !== 'Creatives'}" exact>
              <span>Visuals Analysis</span>
            </router-link>
            <router-link :to="{name: 'Metadata'}" :class="{'not-active' : activeTab !== 'Metadata'}" exact>
              <span>Meta Analysis</span>
            </router-link>
            <router-link :to="{name: 'ReviewsAI'}" :class="{'not-active' : activeTab !== 'ReviewsAI'}" exact>
              <span>Reviews AI Summaries</span>
            </router-link>
          </div>

          <order-radaso-button :pre-selected="orderASOPreSelected" v-if="orderASOPreSelected"/>
        </div>

        <router-view
          :key="currentApp.id + currentCountry.code + activeTab"
          ref="competitors_tab_apps"
          :current-app="currentApp"
          :current-country="currentCountry"
          @competitorsCountChanged="competitorsCountChanged"
          @clicked="(item) => openModel(item)"
        ></router-view>
      </div>

    </template>
    <template v-else>
      <progress-indicator :loaded="resultsLoading">{{ loaderText }}</progress-indicator>
    </template>

    <details-modal v-if="showAppDetailsModal"
                       :opened="showAppDetailsModal"
                       :app-data="clickedItem"
                       @competitor-changed="competitorChanged"
                       @modal-closed="showAppDetailsModal = !showAppDetailsModal"/>

  </div>
</template>

<script>
import {mapGetters} from "vuex";
import ProgressBar from "@/components/UI/ProgressBar";
import ProgressIndicator from "@/components/UI/ProgressIndicator";
import CompetitorBillet from "@/views/CompetitorsFinderPage/components/CompetitorBillet";
import DetailsModal from "@/components/AppDetailsComponent/DetailsModal/index.vue";
import UserLimitsExceeded from "@/components/UI/UserLimitsExceeded/index.vue";
import Competitors_tab_apps from "@/views/CompetitorsFinderPage/Competitors/tab_apps.vue";
import Competitors_tab_creatives from "@/views/CompetitorsFinderPage/Competitors/tab_creatives.vue";
import ProgressBarsHeading from "@/views/CompetitorsFinderPage/components/ProgressBarsHeading/index.vue";
import SvgIcon from "@/components/UI/SvgIcon/index.vue";
import OrderRadasoButton from "@/components/OrderRadaso/Button/index.vue";

export default {
  name: "Competitors",
  components: {
    OrderRadasoButton, SvgIcon,
    ProgressBarsHeading,
    Competitors_tab_apps,
    Competitors_tab_creatives,
    'progress-indicator': ProgressIndicator,
    'competitor-billet': CompetitorBillet,
    ProgressBar,
    DetailsModal,
    UserLimitsExceeded,
  },
  data() {
    return {
      activeTab: 'Competitors_tab_apps',
      loaderText: '',
      resultsLoading: false,
      showAppDetailsModal: false,
      competitorsCount: 0,
      clickedItem: {},
    }
  },
  created() {
    this.setDocumentTitle();
  },
  methods: {
    competitorChanged(e) {
      this.$refs.competitors_tab_apps.competitorChanged(e);
    },
    openModel(item) {
      this.clickedItem = item.mutateToModalAppFormat();
      this.showAppDetailsModal = true;
    },
    competitorsCountChanged(count) {
      this.competitorsCount = count;
    },
    changeSorting(sortBy, order) {
      this.sortData = {
        sort: sortBy,
        order: order,
      };
    },
    setDocumentTitle() {
      let title = this.$route.meta.pageTitle;
      if (this.currentApp?.title) {
        title += ' - ' + this.currentApp.title;
      }
      this.setPageTitle(title);
    },
  },
  computed: {
    ...mapGetters([
      'currentApp',
      'currentCountry',
      'currentCountryManual',
      'userLimits',
      'userSubscription',
    ]),
    overheadType() {
      if (this.userSubscription?.has_overhead_limits && (this.userLimits?.max_apps?.used > this.userLimits?.max_apps?.total)) {
        return 'appsOverhead';
      } else if (this.userSubscription?.has_overhead_limits && (this.userLimits?.max_keywords?.used > this.userLimits?.max_keywords?.total)) {
        return 'keywordsOverhead';
      } else if (this.userSubscription?.has_overhead_limits && (this.userLimits?.max_competitors?.used > this.userLimits?.max_competitors?.total)) {
        return 'competitorsOverhead';
      } else {
        return false;
      }
    },
    orderASOPreSelected() {
      if (this.$route.name === 'MyCompetitors') {
        return ['full-aso-support', 'aso-audit', 'launch-support'];
      } else if (this.$route.name === 'Creatives') {
        return ['visual-aso'];
      } else if (this.$route.name === 'Metadata') {
        return ['metadata-optimization'];
      } else {
        return null;
      }
    },
  },
  watch: {
    async $route(to, from) {
      this.title = to?.meta?.pageTitle ?? '';
    },
    currentApp() {
      this.setDocumentTitle();
    },
  },
}
</script>

<style lang="scss">
.tabs-block {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;

  @media only screen and (max-width: 768px) {
    flex-wrap: wrap;
    gap: 16px;
  }
}
.tab-selector {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.5;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.tab-selector > * {
  margin-right: 15px;
  color: var(--title-color);
}
.tab-selector > :not(.router-link-exact-active) {
  opacity: 0.24
}

.header-tabs-links .tab-item.router-link-active .text {
  opacity: 1;
}
</style>