<script>
import {CustomCompetitor} from "@/api/objects/CustomCompetitor";
import customCompetitors from "@/api/modules/custom-competitors";
import ProgressIndicator from "@/components/UI/ProgressIndicator/index.vue";
import {nextTick, ref} from "vue";
import VueEasyLightbox from "vue-easy-lightbox";
import DetailsModal from "@/components/AppDetailsComponent/DetailsModal/index.vue";
import {cropImage, cropImageToHeight} from "@/utils/utils";

export default {
  name: "ScreenshotsLine",
  components: {DetailsModal, VueEasyLightbox, ProgressIndicator},
  props: {
    competitor: {
      type: CustomCompetitor,
      default: null,
    },
    localeCode: {
      type: String,
      default: null,
    },
    device: {
      type: String,
      default: null,
    },
    showLogo: {
      type: Boolean,
      default: false,
    },
    isSmall: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['open-modal'],
  data() {
    return {
      screenshots: [],
      scrollAllowed: false,
      leftScrollAllowed: false,
      rightScrollAllowed: false,
      loadedImg: 0,
      allLoaded: false,

      indexRef: ref(0),
      imgsRef: ref([]),
      visibleRef: ref(false),
    }
  },
  async mounted() {
    this.screenshots = await customCompetitors.fetchScreenshots(this.competitor.competitorId, this.localeCode, this.device);
    if (!this.screenshots?.length) {
      this.allLoaded = true;
    }
    this.markAngles();
  },
  updated() {
    this.markAngles();
  },
  methods: {
    cropImage,
    cropImageToHeight,
    scroll(factor) {
      let ref = this.$refs.carousel;

      ref.scroll({
        left: ref.scrollLeft + ref.clientWidth * 0.5 * factor,
        behavior: "smooth",
      });
    },
    async handleLoad() {
      if (this.screenshots.length === 0 || ++this.loadedImg >= Math.round(this.screenshots.length / 2)) {
        this.allLoaded = true;
      }

      await nextTick();
      this.markAngles();
    },
    markAngles() {
      let ref = this.$refs.carousel;
      if (!ref) {
        return;
      }

      let maxScroll = ref.scrollWidth - ref.clientWidth - 5;
      let scroll = ref.scrollLeft;

      this.leftScrollAllowed = scroll > 5;
      this.rightScrollAllowed = scroll < maxScroll;

      this.scrollAllowed = this.leftScrollAllowed || this.rightScrollAllowed;
    },
    showSingle(index) {
      this.imgsRef = this.screenshots.map(item => {
        return {
          src: item.getUrl()
        }
      });

      this.indexRef = index;
      this.visibleRef = true;
    },
    openModel(item) {
      this.$emit('open-modal', item);
    },
  }
}
</script>

<template>
  <div class="screenshots-line" :class="{'big':!isSmall}">
    <div class="screenshots-line-top">
      <div class="screenshots-logo" v-if="showLogo" @click="openModel(competitor)">
        <img width="48" height="48" :src="cropImage(competitor.logo, '48x48', competitor.store)">
      </div>
      <div class="screenshots-title">
        <a :href="competitor.storeUrl" target="_blank">
          <svg-icon v-once icon="link-icon" class="link-icon common-app-store-link"/>
        </a>
        <span class="common-app-title" @click="openModel(competitor)">{{ competitor.title }}</span>
      </div>
    </div>
    <div class="creatives-carousel">
      <span v-show="scrollAllowed" class="common-scroll-btn" :class="{'not-allowed' : !leftScrollAllowed}" @click="scroll(-1)"><svg-icon v-once icon="arrow-left"/></span>
      <div v-if="this.screenshots?.length || !allLoaded" class="screenshots-line-bottom" ref="carousel" @scroll="markAngles">
        <div class="screenshot" v-for="(screenshot, index) in this.screenshots">
          <div class="screenshots-skeleton-box" v-if="!allLoaded"></div>
          <img v-show="allLoaded" @load="handleLoad" :src="cropImageToHeight(screenshot.getUrl(), isSmall ? 280 : 380, competitor.store)" @click="showSingle(index)">
        </div>
      </div>
      <div v-else class="text-center">
        <span>😔 No screenshots yet</span>
      </div>
      <span v-show="scrollAllowed" class="common-scroll-btn" :class="{'not-allowed' : !rightScrollAllowed}" @click="scroll(1)"><svg-icon v-once icon="arrow-right"/></span>
    </div>
  </div>
  <vue-easy-lightbox
      :visible="visibleRef"
      :imgs="imgsRef"
      :index="indexRef"
      teleport="body"
      @hide="this.visibleRef = false"
  ></vue-easy-lightbox>
</template>

<style scoped lang="scss">
.screenshots-logo {
  margin-right: 15px;
  width: 48px;
  height: 48px;
  cursor: pointer;
}

.screenshots-line-top {
  display: flex;
  align-items: center;
}

.screenshots-line:not(:first-of-type) {
  margin-top: 20px;
}

.screenshots-title a {
  margin-right: 5px;
}

.screenshots-line-bottom {
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  margin-top: 13px;
  align-items: center;
  padding-bottom: 10px;
  min-height: 299px;
}

.screenshot img {
  height: 280px;
  border-radius: 4px;
  user-select: none;
  cursor: pointer;
}

.screenshots-skeleton-box {
  height: 280px;
  border-radius: 4px;
  user-select: none;
  width: 280px;
  background-color: #e4eaf4;
}

.screenshot {
  margin-right: 15px;
}

.screenshots-line-bottom .screenshot:last-of-type {
  margin-right: 0;
}

.common-scroll-btn {
  position: absolute;
  top: 50%;
  margin-top: -18px;
  left: -18px;
  user-select: none;
}

.common-scroll-btn .svg-icons {
  position: absolute;
  top: 50%;
  font-size: 18px;
  margin: -9px 0 0;
}

.creatives-carousel {
  position: relative;
}

.creatives-carousel .common-scroll-btn:last-of-type {
  right: -18px;
  left: auto;
}

.screenshots-line-bottom::-webkit-scrollbar {
  height: 8px;
}
.screenshots-line-bottom::-webkit-scrollbar-thumb {
  background: var(--scroll-bar-screen);
}
.screenshots-line-bottom::-webkit-scrollbar-thumb:hover {
  background: var(--scroll-bar-screen);
}

.common-app-title {
  cursor: pointer;
}

.screenshots-line.big {
  .screenshots-line-bottom {
    min-height: 399px;
  }

  .screenshot img {
    height: 380px;
  }

  .screenshots-skeleton-box {
    height: 380px;
  }
}
</style>