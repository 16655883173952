<template>
  <simple-language-selector :current-language="selectedLocale?.code"
                            :current-country-name="currentKeywordCountryName" 
                            :languages="localesObject" 
                            placeholder="Choose locales" 
                            loader-text="Loading locales..." 
                            :is-languages-loaded="true" 
                            @language-changed="changeLocale"
  />
</template>

<script>
import SimpleLanguageSelector from "@/components/LanguageSelector/index.vue";
import {getLocaleCountryCode} from "@/configs/locales-flags";
import {httpRequest} from "@/api";

export default {
  name: "LanguageSelector",
  components: {SimpleLanguageSelector},
  props: {
    initialSelection: {
      type: Object,
      default: null,
    },
    currentApp: {
      type: Object,
      default: null,
    },
    currentCountry: {
      type: Object,
      default: null,
    },
    currentKeywordCountryName: {
      type: String,
      default: null,
    },
    countryLocalesConfig: {
      type: Object,
      default: null,
    },
    placeholder: {
      type: String,
      default: 'Choose language',
    }
  },
  emits: [
    'locale-changed',
  ],
  data() {
    return {
      localesObject: {},
      selectedLocale: {}
    }
  },
  mounted() {
    this.fetchCountryLocales();
  },
  methods: {
    changeLocale(code, name) {
      this.selectedLocale.code = code;
      this.selectedLocale.name = name;

      this.$emit('locale-changed', this.selectedLocale);
    },
    async fetchCountryLocales() {
      const localesData = await httpRequest(
        'GET',
        process.env.VUE_APP_API_URL + this.$ApiUrls.user.COUNTRY_LOCALES + `/${this.currentApp?.store?.key}/${this.currentCountry?.code}`
      );
      this.localesObject = {
        default: localesData?.default,
        popular: this.setLocales(localesData?.popular),
        other: this.setLocales(localesData?.other),
      };

      this.changeLocale(
        this.localesObject?.default,
        getLocaleCountryCode(this.localesObject?.default)?.localeName
      );
    },
    setLocales(obj) {
      let localesArr = [];
      for (const key in obj) {
        localesArr.push({
          code: key,
          name: obj[key],
        })
      }
      return localesArr;
    },
  },
  watch: {
    initialSelection() {
      if (this.initialSelection?.code) {
        this.selectedLocale = this.initialSelection;
      }
    },
  },
}
</script>
