<script>
import ScreenshotsLine from "@/views/CompetitorsFinderPage/Competitors/Creatives/Items/SreenshotsLine.vue";
import DetailsModal from "@/components/AppDetailsComponent/DetailsModal/index.vue";
import {getScrollPercent} from "@/utils/utils";

export default {
  name: "CreativesScreenshots",
  components: {DetailsModal, ScreenshotsLine},
  props: {
    creativesState: {
      type: Object,
      default: null,
    },
    showLogo: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['competitor-count-changed'],
  data() {
    return {
      showCount: 5,

      showAppDetailsModal: false,
      clickedItem: {},
    }
  },
  methods: {
    handleScroll() {
      if (this.showCount >= this.creativesState.competitors.length) return;

      let percent = getScrollPercent(document);

      if (percent > 70) {
        this.showCount += 5;
      }
    },
    openModel(item) {
      this.clickedItem = item.mutateToModalAppFormat();
      this.showAppDetailsModal = true;
    },
  },
}
</script>

<template>
  <div v-scroll="handleScroll" class="screenshots-container" v-if="this.creativesState.locale && this.creativesState.competitors.length" :key="this.creativesState.locale.code + this.creativesState.device">
    <template v-for="(competitor, index) in this.creativesState.competitors">
      <screenshots-line v-if="index < showCount"
                        :key="competitor.competitorId"
                        :competitor="competitor"
                        :locale-code="this.creativesState.locale.code"
                        :is-small="this.creativesState.small"
                        :device="creativesState.device"
                        @open-modal="openModel"
                        :show-logo="showLogo"></screenshots-line>
    </template>
  </div>
  <details-modal v-if="showAppDetailsModal"
                 :opened="showAppDetailsModal"
                 :app-data="clickedItem"
                 @competitor-changed="this.$emit('competitor-count-changed', $event)"
                 @modal-closed="showAppDetailsModal = !showAppDetailsModal"/>
</template>

<style scoped lang="scss">
.screenshots-container {
  background-color: #fff;
  padding: 25px;
  border-radius: 8px;
  box-shadow: 0px 6px 12px 0px #A0BEDD3D;
  margin-top: 10px;
}
</style>